















import { Vue, Component } from "vue-property-decorator";
import { SearchIcon } from "vue-feather-icons";
import options from "~/options.json";

@Component({
  components: { SearchIcon },
})
export default class NavHeader extends Vue {
  private links = options.links;

  private name: string = options.name;

  private async logout() {
    await this.$auth.logout();
    this.$NotifyToast("logged out");
  }
}
