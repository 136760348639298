









import { toastStore } from "~/store";
import ToastInfo from "~/util/ToastInfo";
import { Vue, Component } from "vue-property-decorator";
import { XIcon } from "vue-feather-icons";

@Component({
  components: { XIcon },
})
export default class ToastDisplay extends Vue {
  get toasts() {
    return toastStore.toasts;
  }

  getClass(toast: ToastInfo) {
    return toast.error ? "toast--error" : "toast--success";
  }

  closeToast(id: number) {
    toastStore.removeToast(id);
  }
}
